<template>
  <b-modal
    :id="id"
    class="m-0 p-0"
    hide-header
    hide-footer
    no-close-on-backdrop
  >
    <div class="row m-0 p-0">
      <div class="col-12 m-0 p-0">
        <p class="font-600">Edit (SKU {{ data.SkCode }})</p>
      </div>
      <div class="col-12">
        <div class="form-group mb-3">
          <label for="exampleFormControlInput1">StartDate</label>
          <input
            :disabled="id == 'ModalInProgress'"
            type="date"
            class="form-control "
            v-model="data.StartDate"
          />
        </div>

        <div class="form-group mb-3">
          <label for="exampleFormControlInput1">EndDate</label>
          <div class="input-group ">
            <input type="date" class="form-control " v-model="data.EndDate" />
            <div class="input-group-append">
              <button class="btn bt-main" @click="set50y">50 YEAR</button>
            </div>
          </div>
        </div>
      </div>

      <!-- ---------- footer ---------------- -->
      <div class="col-12 text-right">
        <button type="button" class="btn  bt-main mx-1" @click="confirm">
          ยืนยัน
        </button>
        <button type="button" class="btn  bt mx-1" @click="hide">ยกเลิก</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalEditOnOff",
  props: ["pdata", "id"],
  data() {
    return {
      data: {
        Id: 0,
        SkCode: "",
        StartDate: null,
        EndDate: null,
        Active: "",
        Remark: "",
        UpdateBy: "",
      },
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.setdata();
  },
  methods: {
    set50y() {
      // data.EndDate;
      const date = new Date();
      date.setFullYear(date.getFullYear() + 50);

      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let to = `${year}-${String(month).length == 1 ? `0${month}` : month}-${
        String(day).length == 1 ? `0${day}` : day
      }`;
     
      this.data.EndDate = to;
    },
    async setdata() {
      if (this.pdata !== "") {
        let {
          Id,
          SkCode,
          StartDate,
          EndDate,
          Active,
          Remark,
          UpdateBy,
        } = this.pdata;
        this.data = {
          Id: Id,
          SkCode: SkCode,
          StartDate: StartDate,
          EndDate: EndDate,
          Active: Active,
          Remark: Remark,
          UpdateBy: UpdateBy,
        };
      }
    },
    redata() {
      this.data = {
        Id: 0,
        SkCode: "",
        StartDate: null,
        EndDate: null,
        Active: "",
        Remark: "",
        UpdateBy: "",
      };
    },
    async confirm() {
      try {
        let caldiff = await await this.$serviceMain.CalDiffdate(
          this.data.StartDate,
          this.data.EndDate
        );
        let { diff } = caldiff;
        if (diff < 0) {
          throw `วันที่สิ้นสุดต้องมากกว่าวันที่เริ่มต้น/End date have to more than start date`;
        }

        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          let { id } = this.$store.getters["loginActions/GET_USER"];
          let data = { ...this.data, user_id: id, mode: "edit" };
          console.log(data);
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `manage/updatePimProductShowOnWeb`,
            { datas: data },
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          await this.$store.dispatch(
            "OnOffOnWebActions/getPimProductShowOnWeb"
          );
          this.hide();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    hide() {
      this.redata();
      this.$bvModal.hide(`${this.id}`);
    },
  },
};
</script>

<style></style>
