<template>
  <div class="container-fliud">
    <div class="row">
      <div class="row w-100 ">
        <div class="col-12 col-xl-4 mb-3">
          <div class="input-group input-group-sm   ">
            <div class="input-group-prepend input-group-sm">
              <select class="custom-select" v-model="mode">
                <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                  {{ e.name }}
                </option>
              </select>
            </div>
            <input
              type="search"
              class="form-control form-control-sm"
              placeholder="ค้นหา / Seach"
              v-model="find"
              v-on:keyup.enter="Search()"
            />
            <div class="input-group-append">
              <button class="btn btn-sm bt-main" @click="Search()">
                ค้นหา
              </button>
            </div>
          </div>
        </div>
        <div class="col-12  ">
          <p class="px-3 font-600 w-100" style="display: flex">
            สินค้า&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ
            <span style="flex: 1; text-align: right; white-space: nowrap;"
              ><button class="btn btn-sm bt-bnb" @click="export_excel">
                นำออกข้อมูล <span v-html="$svg_icon.excel"></span></button
            ></span>
          </p>
        </div>
      </div>
      <div class="col-12">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          :current-page="page_num"
          :per-page="page_size"
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
          <template #cell(Mode)="row">
            <span
              :class="[row.item.Mode == 'on' ? 'badge-bnb' : 'badge-twd']"
              class="badge"
              >{{ row.item.Mode.toUpperCase() }}</span
            >
          </template>
          <template #cell(Active)="row">
            <span
              :class="[row.item.Active == 1 ? 'badge-main' : 'badge-twd']"
              class="badge"
              >{{ row.item.Active == 0 ? "No" : "Yes" }}</span
            >
          </template>
          <template #cell(Action)="row">
            <span
              v-if="check_action(row.item)"
              type="button"
              class="text-muted mx-2"
              v-html="$svg_icon.edit"
              @click="edit(row)"
            ></span>
            <span
              v-if="check_action(row.item)"
              type="button"
              class="text-twd mx-2"
              v-html="$svg_icon.remove"
              @click="remove(row)"
            ></span>
          </template>
        </b-table>
      </div>
      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
            <select class="custom-select" v-model="page_size">
              <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                {{ e }}
              </option>
            </select>
          </div>
          <div class="col-6 col-xl-3">
            <b-pagination
              v-model="page_num"
              :total-rows="totalRows"
              :per-page="page_size"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <ModalEditOnOff :key="remodal" :pdata="pdata" id="ModalExpired" />
  </div>
</template>

<script>
import ModalEditOnOff from "@/components/Manage/OnOffOnWeb/ModalEditOnOff.vue";
export default {
  name: "Expired",
  components: { ModalEditOnOff },
  data() {
    return {
      pdata: "",
      remodal: Date.now() + "e",
      mode: "SkCode",
      select_mode: [
        { name: "ค้นหาด้วย SKU", key: "SkCode" },
        { name: "ค้นหาด้วย Remark", key: "Remark" },
      ],
      totalRows: 0,
      find: "",
      items: [],
      itemlist: [],
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      fields: [
        { key: "SkCode", label: "SkCode" },
        { key: "StartDate", label: "StartDate", sortable: true },
        { key: "EndDate", label: "EndDate", sortable: true },
        {
          key: "Remark",
          label: "Remark",
          thStyle: { width: "30%" },
          sortable: true,
        },
        {
          key: "user_request",
          label: "User Request",
        },
        { key: "Status", label: "Status" },
        { key: "Mode", label: "Mode", class: "text-center" },
        { key: "ShowOnWeb", label: "ShowOnWeb", class: "text-center" },
        // { key: "Active", label: "Active", class: "text-center" },
        { key: "UpdateBy", label: "UpdateBy" },

        {
          key: "Action",
          label: "",
          class: "text-right",
          thStyle: { width: "10%" },
        },
      ],
    };
  },
  watch: {
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
    GET_Expired: function(v) {
      this.getdata();
    },
  },
  computed: {
    GET_Expired: function() {
      return this.$store.getters["OnOffOnWebActions/GET_Expired"];
    },
  },
  mounted() {
    this.getdata();
  },
  methods: {
    async export_excel() {
      let date = `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}-${new Date().getTime()}`;

      let json = this.itemlist;
      let name = `Expired(${date}).xlsx`;
      this.$serviceMain.JsonToExcat(json, name);
      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    check_action(data) {
      let { Active, Status } = data;
      if (Active == 1 && Status !== "Expired" && Status !== "Deleted") {
        return true;
      } else {
        return false;
      }
    },
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    Search() {
      var results = [];
      if (this.find == "") {
        // this.$emit("getProductByRemark");
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
    async edit(data) {
      let { item, index } = data;
      let { SkCode } = item;
      this.pdata = item;
      this.remodal = Date.now() + "e";

      setTimeout(() => {
        this.$bvModal.show("ModalExpired");
      }, 100);
    },
    async remove(data) {
      let { item, index } = data;
      let { SkCode } = item;

      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        `คุณต้องการลบ ${SkCode} / You need to delete ${SkCode}?`
      );
      if (confirm) {
        let { id } = this.$store.getters["loginActions/GET_USER"];
        let data = { ...item, user_id: id, mode: "remove" };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `manage/updatePimProductShowOnWeb`,
          { datas: data },
          1
        );
        this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        await this.$store.dispatch("OnOffOnWebActions/getPimProductShowOnWeb");
      }
    },
    async getdata() {
      try {
        let getAPI = await this.$store.getters["OnOffOnWebActions/GET_Expired"];
        if (getAPI !== null) {
          this.items = getAPI;
          this.itemlist = getAPI;
          this.totalRows = getAPI.length;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style></style>
