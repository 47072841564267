<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">On-Off On Web</p>
          </div>
        </div>

        <div class="row m-0 p-0 w-100">
       
          <div class="col-12  "  >
            <b-tabs v-model="tabIndex" content-class="mt-1 p-3">
              <b-tab title="All" :title-link-class="linkClass(0)" active>
                <OnOffSkuList />
              </b-tab>
              <b-tab title="In Coming" :title-link-class="linkClass(1)">
                <template #title>
                  <p class="m-0 p-0 row align-items-center">
                    In Coming
                    <span
                      v-if="numincoming > 0"
                      class="font-600 text-main font-0-8s mx-1"
                      >({{ numincoming }})</span
                    >
                    <b-icon
                      v-if="numincoming > 0"
                      icon="smartwatch"
                      animation="fade"
                      variant="main"
                      font-scale="0.8"
                    ></b-icon>
                  </p>
                </template>
                <InComing />
              </b-tab>
              <b-tab title="In Progress" :title-link-class="linkClass(2)">
                <template #title>
                  <p class="m-0 p-0 row align-items-center">
                    In Progress
                    <span
                      v-if="numinprogress > 0"
                      class="font-600 text-bnb font-0-8s mx-1"
                      >({{ numinprogress }})</span
                    >
                    <b-icon
                      v-if="numinprogress > 0"
                      icon="disc-fill"
                      animation="spin"
                      variant="bnb"
                      font-scale="0.8"
                    ></b-icon>
                  </p>
                </template>
                <InProgress />
              </b-tab>
              <b-tab title="Expired" :title-link-class="linkClass(3)">
                <Expired />
              </b-tab>
              <b-tab title="Deleted" :title-link-class="linkClass(4)">
                <Deleted />
              </b-tab>
              <b-tab title="Error" :title-link-class="linkClass(5)">
                <template #title>
                  <p class="m-0 p-0 row align-items-center">
                    Error
                    <span
                      v-if="numerror > 0"
                      class="font-600 text-danger font-0-8s mx-1"
                      >({{ numerror }})</span
                    >
                    <b-icon
                      v-if="numerror > 0"
                      icon="info-circle-fill"
                      animation="fade"
                      variant="twd"
                      font-scale="0.8"
                    ></b-icon>
                  </p>
                </template>
                <ErrorShowOnWeb />
              </b-tab>
              <b-tab title="Upload" :title-link-class="linkClass(6)">
                <UploadNewOnOff />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadNewOnOff from "@/components/Manage/OnOffOnWeb/UploadNewOnOff.vue";
import OnOffSkuList from "@/components/Manage/OnOffOnWeb/OnOffSkuList.vue";
import InComing from "@/components/Manage/OnOffOnWeb/InComing.vue";
import InProgress from "@/components/Manage/OnOffOnWeb/InProgress.vue";
import Expired from "@/components/Manage/OnOffOnWeb/Expired.vue";
import Deleted from "@/components/Manage/OnOffOnWeb/Deleted.vue";
import ErrorShowOnWeb from "@/components/Manage/OnOffOnWeb/ErrorShowOnWeb.vue";
 

export default {
  name: "OnOffOnWeb",
  components: {
    UploadNewOnOff,
    OnOffSkuList,
    InComing,
    InProgress,
    Expired,
    Deleted,
    ErrorShowOnWeb,
   
  },
  data() {
    return {
    
      numerror: 0,
      numincoming: 0,
      numinprogress: 0,
      tabIndex: 0,
    };
  },
  watch: {
    GET_Error: function(v) {
      this.see_error();
    },
    GET_In_Coming: function(v) {
      this.see_incoming();
    },
    GET_In_Progress: function(v) {
      this.see_inprogress();
    },
    
  },
  computed: {
    GET_Error: function() {
      return this.$store.getters["OnOffOnWebActions/GET_Error"];
    },
    GET_In_Coming: function() {
      return this.$store.getters["OnOffOnWebActions/GET_In_Coming"];
    },
    GET_In_Progress: function() {
      return this.$store.getters["OnOffOnWebActions/GET_In_Progress"];
    },
    
  },
  async mounted() {
    await this.$store.dispatch("OnOffOnWebActions/getPimProductShowOnWeb");
  },
 
  methods: {
 
    async see_error() {
      let data = await this.$store.getters["OnOffOnWebActions/GET_Error"];
      if (data == null) {
        this.numerror = 0;
      } else {
        this.numerror = data.length;
      }
    },
    async see_incoming() {
      let data = await this.$store.getters["OnOffOnWebActions/GET_In_Coming"];
      if (data == null) {
        this.numincoming = 0;
      } else {
        this.numincoming = data.length;
      }
    },
    async see_inprogress() {
      let data = await this.$store.getters["OnOffOnWebActions/GET_In_Progress"];
      if (data == null) {
        this.numinprogress = 0;
      } else {
        this.numinprogress = data.length;
      }
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["text-main", "font-weight-bold"];
      } else {
        return ["text-muted"];
      }
    },
  },
};
</script>

<style></style>
